const _cn = "  is_Text font_body _col-4y8rte _fow-600 _mt-0px _mr-0px _mb-0px _ml-0px _ww-break-word _bxs-border-box _ff-4yewjq _dsp-inline ";
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useForm, useSubmit } from '@vici/form';
import { useTheme, Button, Text } from 'tamagui';
type ButtonProps = {
  title?: string;
};
const FormSubmitButton: React.FC<ButtonProps> = ({
  title = text.defaultTitle
}) => {
  const submit = useSubmit();
  const theme = useTheme();
  const isSubmitting = useForm.use.isSubmitting();
  // const isValid = useIsFormValid()
  return <Button onPress={submit} backgroundColor="$primary" marginVertical="$4">
      {isSubmitting ? <ActivityIndicator color={theme.dark.val} /> : <span className={_cn}>
          {title}
        </span>}
    </Button>;
};
const text = {
  defaultTitle: 'Submit'
};
export default FormSubmitButton;

require("/vercel/path0/apps/vellapps/components/form/base/FormSubmitButton.tsx.77.tamagui.css!=!/vercel/path0/deployments/vellapps-next/node_modules/tamagui-loader/dist/cjs/css.js?cssPath=/vercel/path0/apps/vellapps/components/form/base/FormSubmitButton.tsx.77.tamagui.css!/vercel/path0/apps/vellapps/components/form/base/FormSubmitButton.tsx")