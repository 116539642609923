import { Paragraph } from 'tamagui';
import * as React from 'react';
type Props = React.ComponentPropsWithRef<typeof Paragraph> & {
  /**
   * Type of the helper text.
   */
  type: 'error' | 'info';
  /**
   * Whether to display the helper text.
   */
  visible?: boolean;
  /**
   * TestID used for testing purposes
   */
  testID?: string;
  errorColor?: string;
};
const HelperText: React.FC<Props> = ({
  type = 'info',
  visible = true,
  errorColor = '$red10Dark',
  ...rest
}) => {
  return <Paragraph pt="$2" color={type === 'error' ? errorColor : '$textSecondary'} enterStyle={{
    opacity: 0
  }} opacity={1} {...rest}>
      {(type === 'error' ? visible ? rest.children : '' : rest.children) || '\u00A0'}
    </Paragraph>;
};
export default HelperText;