const _cn3 = "  is_Paragraph font_body _pt-1aj14ca _pr-1aj14ca _pb-1aj14ca _pl-1aj14ca _als-center _maw-qnlou8 _ta-center _lh-3or5x5 _fos-3slq2o _ls-5wa _col-fbw504 _ff-4yewjq _ussel-auto _mt-0px _mr-0px _mb-0px _ml-0px _ww-break-word _bxs-border-box _dsp-inline ";
const _cn2 = "  is_Separator _als-center _mt-lrpiu9 _mr-lrpiu9 _mb-lrpiu9 _ml-lrpiu9 _w-qnlo3j _bbw-1px _mah-0px _h-0px _fg-1 _fs-1 _fb-auto _btw-1aj14bf _brw-1aj14bf _blw-1aj14bf _btc-qrc2f2 _brc-qrc2f2 _bbc-qrc2f2 _blc-qrc2f2 _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _dsp-flex _ai-stretch _bbs-solid _bts-solid _bls-solid _brs-solid _transform-n24na2 ";
const _cn = "  is_H1 font_heading _ta-center _col-fbw504 _tt-1b7l12b _ff-4yewjq _fow-1cfkkj7 _ls-1dnk403 _fos-1all9bv _lh-17a9wki _mt-0px _mr-0px _mb-0px _ml-0px _ussel-auto _ww-break-word _bxs-border-box _dsp-inline ";
import { Button, H1, Paragraph, Separator, Stack, styled } from 'tamagui';
export const HeaderBackground = styled(Stack, {
  name: 'HeaderBackground',
  "backgroundColor": "_bg-1n9dn6"
});
const Header: React.FC<{
  onPressCTA: () => void;
}> = ({
  onPressCTA
}) => <HeaderBackground paddingVertical={64}>
    <h1 className={_cn}>
      Vellapps LLC
    </h1>
    <div className={_cn2} />
    <p className={_cn3}>
      Delivering apps together to help you grow your business and operate with ease.
    </p>
    <Button onPress={onPressCTA} als="center" mt="$5" backgroundColor="$accent" maxWidth="$20">
      Get Started
    </Button>
  </HeaderBackground>;
export default Header;

require("/vercel/path0/apps/vellapps/components/Heading.tsx.71.tamagui.css!=!/vercel/path0/deployments/vellapps-next/node_modules/tamagui-loader/dist/cjs/css.js?cssPath=/vercel/path0/apps/vellapps/components/Heading.tsx.71.tamagui.css!/vercel/path0/apps/vellapps/components/Heading.tsx")