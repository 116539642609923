const _cn = "  is_Stack _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import * as React from 'react';
import type { TextInputProps, TextInput as RNTextInput } from 'react-native';
import { Stack, Label, Input } from 'tamagui';
import HelperText from './base/text-input/helper-text';
import { useFieldRef, useFieldError, useSetFieldData, useFieldData } from '@vici/form';
export interface TextFieldProps extends Omit<TextInputProps, 'value'> {
  name: string;
  label: string;
  required?: boolean;
  helperText?: string;
}
const TextInput = React.forwardRef<Record<string, unknown>, TextFieldProps>(({
  name,
  required,
  label,
  helperText,
  ...props
}, forwardedRef: React.Ref<unknown>) => {
  const value = useFieldData<string>(name) || '';
  const setValue = useSetFieldData(name);
  // TODO: use ref once we have a way to pass this to tamagui
  // Current error: TypeError: Cannot assign to read only property 'current' of object '#<Object>'
  useFieldRef<RNTextInput>(forwardedRef, name);
  const error = useFieldError(name);
  return <>
        <div className={_cn}>
          <Label color="$text" htmlFor={name}>
            {label}
            {required ? '*' : ''}
          </Label>
          <Input {...props} color="$text" borderColor="$text" accessibilityLabel={label} id={name} value={value} onChangeText={setValue}
      // ref={ref}
      />
        </div>
        <HelperText type="error">{error?.[0]}</HelperText>
        {!!helperText && <HelperText type="info">{helperText}</HelperText>}
      </>;
});
export default TextInput;

require("/vercel/path0/apps/vellapps/components/form/TextInput.tsx.73.tamagui.css!=!/vercel/path0/deployments/vellapps-next/node_modules/tamagui-loader/dist/cjs/css.js?cssPath=/vercel/path0/apps/vellapps/components/form/TextInput.tsx.73.tamagui.css!/vercel/path0/apps/vellapps/components/form/TextInput.tsx")