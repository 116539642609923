import React from 'react';
import type { NativeSyntheticEvent, TextInputContentSizeChangeEventData } from 'react-native';
import type { GetProps } from 'tamagui';
import { TextArea } from 'tamagui';
export type TextAreaProps = GetProps<typeof TextArea>;
const AutoExpandTextArea = React.forwardRef<Record<string, unknown>, TextAreaProps>(props => {
  const [height, setHeight] = React.useState(200);
  const onTextLayout = React.useCallback((e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    const newHeight = e.nativeEvent.contentSize.height;
    if (height !== newHeight) {
      setHeight(Math.max(newHeight, 150));
    }
  }, [height, setHeight]);
  return <TextArea {...props} onContentSizeChange={onTextLayout} height={height} overflow="hidden" />;
});
export default AutoExpandTextArea;