import LogoDark from '../assets/logo-dark.svg';
import LogoLight from '../assets/logo-light.svg';
import { useThemeName } from 'tamagui';

const Logo = () => {
  const theme = useThemeName();
  const props = { width: 100, height: 45, viewBox: '0 0 990 450' };
  return theme === 'dark' ? <LogoDark {...props} /> : <LogoLight {...props} />;
};

export default Logo;
