import React from 'react';
import { useForm } from '@vici/form';
import { AlertDialog, Button, XStack, YStack } from 'tamagui';
const Confirmation = () => {
  const isConfirmationVisible = useForm.use.isConfirmationVisible();
  const setIsConfirmationVisible = useForm.use.setIsConfirmationVisible();
  const closeConfirmation = () => setIsConfirmationVisible(false);
  return <AlertDialog native open={isConfirmationVisible}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay key="overlay" animation="quick" o={0.5} enterStyle={{
        o: 0
      }} exitStyle={{
        o: 0
      }} />
        <AlertDialog.Content bordered borderColor="$text" elevate key="content" marginHorizontal="$4" animation={['quick', {
        opacity: {
          overshootClamping: true
        }
      }]} enterStyle={{
        x: 0,
        y: -20,
        opacity: 0,
        scale: 0.9
      }} exitStyle={{
        x: 0,
        y: 10,
        opacity: 0,
        scale: 0.95
      }} x={0} scale={1} opacity={1} y={0}>
          <YStack space>
            <AlertDialog.Title color="$text">Request Received</AlertDialog.Title>
            <AlertDialog.Description color="$text">
              I will get back to you as soon as possible to set up a quick call to discuss your
              needs and set up a quote.
            </AlertDialog.Description>

            <XStack space="$3" jc="flex-end">
              <AlertDialog.Action asChild>
                <Button color="$text" onPress={closeConfirmation}>
                  Ok
                </Button>
              </AlertDialog.Action>
            </XStack>
          </YStack>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>;
};
export default Confirmation;