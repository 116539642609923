const _cn2 = "  is_YStack _zi-10 _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn = "  is_YStack _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import * as React from 'react';
import { Check, ChevronDown, ChevronUp } from '@tamagui/lucide-icons';
import { useFieldError, useFieldData, useSetFieldData } from '@vici/form';
import HelperText from './base/text-input/helper-text';
import { Adapt, Label, Sheet, YStack } from 'tamagui';
import { Select } from '@tamagui/select';
type SelectorProps = {
  name: string;
  label: string;
  required: boolean;
  options: {
    label: string;
    name: string;
  }[];
};
const Selector: React.FC<SelectorProps> = ({
  name,
  label,
  required,
  options
}) => {
  const value = useFieldData<string>(name);
  const setValue = useSetFieldData(name);
  // const [value, setValue] = useField<string>(name);
  const error = useFieldError(name);
  return <div className={_cn}>
      <Label color="$text" htmlFor={name}>
        {label}
        {required ? '*' : ''}
      </Label>
      <Select id={name} value={value} onValueChange={setValue}>
        <Select.Trigger iconAfter={ChevronDown} color="$text" borderColor="$text">
          <Select.Value placeholder="Something" />
        </Select.Trigger>

        <Adapt when="sm" platform="touch">
          <Sheet modal dismissOnSnapToBottom>
            <Sheet.Frame>
              <Sheet.ScrollView>
                <Adapt.Contents />
              </Sheet.ScrollView>
            </Sheet.Frame>
            <Sheet.Overlay />
          </Sheet>
        </Adapt>

        <Select.Content zIndex={200_000}>
          <Select.ScrollUpButton ai="center" jc="center" pos="relative" w="100%" h="$3">
            <div className={_cn2}>
              <ChevronUp color="$text" size={20} />
            </div>
          </Select.ScrollUpButton>

          <Select.Viewport minWidth={200}>
            <Select.Group borderColor="$text">
              {options.map(({
              label,
              name
            }, i) => {
              return <Select.Item index={i} key={name} value={name}>
                    <Select.ItemText color="$text">{label}</Select.ItemText>
                    <Select.ItemIndicator ml="auto">
                      <Check size={16} />
                    </Select.ItemIndicator>
                  </Select.Item>;
            })}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select>
      <HelperText type="error">{error?.[0]}</HelperText>
    </div>;
};
export default Selector;

require("/vercel/path0/apps/vellapps/components/form/Selector.tsx.75.tamagui.css!=!/vercel/path0/deployments/vellapps-next/node_modules/tamagui-loader/dist/cjs/css.js?cssPath=/vercel/path0/apps/vellapps/components/form/Selector.tsx.75.tamagui.css!/vercel/path0/apps/vellapps/components/form/Selector.tsx")