const _cn16 = "  is_YStack _pb-1aj14ca _maw-qnlov3 _w-9011 _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn15 = "  is_YStack _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-center ";
const _cn14 = "  is_H2 font_heading _tt-3tb9kn _ff-4yewjq _fow-3uqciv _ls-3w5fh3 _lh-3or5y0 _mt-0px _mr-0px _mb-0px _ml-0px _ussel-auto _ww-break-word _bxs-border-box _dsp-inline  _pt-lrpixp _pr-1aj14e0 _pb-1aj14e0 _pl-1aj14e0 _fos-1all9bv _ta-center _col-5745x7 ";
const _cn13 = "  is_Paragraph font_body _lh-3or5x5 _fos-3slq2o _maw-400px _ta-center _col-5745x7 _ff-4yewjq _ussel-auto _mt-0px _mr-0px _mb-0px _ml-0px _ww-break-word _bxs-border-box _dsp-inline ";
const _cn12 = "  is_H3 font_heading _ta-center _pt-1aj14ca _pb-1aj14ca _fos-3slq3j _col-5745x7 _tt-3tb9js _ff-4yewjq _fow-3uqci0 _ls-3w5fg8 _lh-3or5x5 _mt-0px _mr-0px _mb-0px _ml-0px _ussel-auto _ww-break-word _bxs-border-box _dsp-inline ";
const _cn11 = "  is_Stack _w-10011 _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0  _ai-center  _w-_gtSm_3011";
const _cn10 = "  is_YStack _pb-lrpixp _pt-lrpiu9 _pr-lrpiu9 _pl-lrpiu9 _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn9 = "  is_H2 font_heading _pt-lrpixp _pr-1aj14e0 _pb-1aj14e0 _pl-1aj14e0 _fos-1all9bv _ta-center _col-5745x7 _tt-3tb9kn _ff-4yewjq _fow-3uqciv _ls-3w5fh3 _lh-3or5y0 _mt-0px _mr-0px _mb-0px _ml-0px _ussel-auto _ww-break-word _bxs-border-box _dsp-inline ";
const _cn8 = "  is_YStack _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch  _fd-_sm_column _fd-_gtSm_row";
const _cn7 = "  is_Stack _jc-center _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-center  _mb-_sm_lrpiu9";
const _cn6 = "  is_YStack _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch  _fd-_sm_column _fd-_gtSm_1b2fkz3";
const _cn5 = "  is_YStack _pt-lrpixp _pb-lrpixp _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-center ";
const _cn4 = "  is_YStack _pt-60px _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn3 = "  is_Text font_heading _col-5745x7 _fos-32px _fow-700 _pl-12px _mt-0px _mr-0px _mb-0px _ml-0px _ww-break-word _bxs-border-box _ff-4yewjq _dsp-inline ";
const _cn2 = "  is_XStack _pt-9px _pr-9px _pb-9px _pl-9px _fd-row _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
const _cn = "  is_Nav _fd-column _miw-0px _mih-0px _pos-relative _bxs-border-box _fb-auto _dsp-flex _fs-0 _ai-stretch ";
import React from 'react';
import { z } from 'zod';
import TextInput from 'vellapps/components/form/TextInput';
import TextInputArea from 'vellapps/components/form/TextInputArea';
import FormSubmitButton from 'vellapps/components/form/base/FormSubmitButton';
import Selector from 'vellapps/components/form/Selector';
import { ErrorBoundary } from 'react-error-boundary';
import BusinessServices from 'vellapps/assets/business_services.svg';
import Consultation from 'vellapps/assets/consultation.svg';
import CustomerNeeds from 'vellapps/assets/customer_needs.svg';
import Experience from 'vellapps/assets/experience.svg';
import ProofOfConcept from 'vellapps/assets/proof_of_concept.svg';
import Header from 'vellapps/components/Heading';
import Logo from 'vellapps/components/Logo';
import Confirmation from 'vellapps/components/form/Confirmation';
import { H2, H3, Nav, Paragraph, Stack, Text, useWindowDimensions, XStack, YStack } from 'tamagui';
import { Form } from '@vici/form';
const schema = z.object({
  name: z.string().min(1, {
    message: 'Full name is required'
  }),
  email: z.string().email().min(1, {
    message: 'Email is required'
  }),
  proposalType: z.string().min(1, {
    message: 'Proposal type is required'
  }),
  description: z.string().min(1, {
    message: 'Description is required'
  })
});
const options = [{
  label: 'Business services',
  name: 'bizweb'
}, {
  label: 'Proof of Concept',
  name: 'ideate-poc'
}, {
  label: 'Consultation',
  name: 'consultation'
}];
const initialValues = {
  name: '',
  email: '',
  proposalType: undefined,
  description: ''
};
export default function App() {
  const requestQuoteRef = React.useRef<HTMLDivElement>(null);
  const scrollToRequestQuote = React.useCallback(() => {
    if (requestQuoteRef.current) {
      requestQuoteRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, []);
  const onSuccess = React.useCallback(async (data: z.infer<typeof schema>) => {
    const response = await fetch('/api/proposal', {
      body: JSON.stringify(data),
      method: 'post'
    });
    if (response.ok) {
      console.log('Successfully submitted form');
    }
  }, []);
  const {
    width
  } = useWindowDimensions();
  return <>
      <nav style={{
      top: 0,
      left: 0,
      right: 0,
      position: 'absolute'
    }} className={_cn}>
        <div className={_cn2}>
          <Logo />
          <span className={_cn3}>
            Vellapps
          </span>
        </div>
      </nav>
      <div className={_cn4}>
        <Header onPressCTA={scrollToRequestQuote} />
      </div>
      <div className={_cn5}>
        <div className={_cn6}>
          <div className={_cn7}>
            <CustomerNeeds width={165} height={120} viewBox="0 0 816.21532 621" />
          </div>
          <Paragraph fontSize="$8" lineHeight="$8" color="$text" maxWidth={width - 92} $gtXs={{
          maxWidth: '$xs'
        }} width="100%">
            I'll help you create a custom app, website or both, suited to you and your customers'
            exact needs. We'll work together to align on a design that's best suited to help you
            surpass your business's goals and attract new clients.
          </Paragraph>
        </div>
      </div>
      <div className={_cn5}>
        <div className={_cn8}>
          <div className={_cn7}>
            <Experience width={200} height={100} viewBox="0 0 1139.17088 654.54324" />
          </div>
          <Paragraph fontSize="$8" lineHeight="$8" color="$text" maxWidth={width - 92} $gtXs={{
          maxWidth: '$xs'
        }} width="100%">
            I'm bringing over 3 years of web development and 5 years of mobile development to the
            table. I've worked with several large companies and many small-scale clients to create
            apps that help grow their business.
          </Paragraph>
        </div>
      </div>
      <h2 className={_cn9}>
        Services
      </h2>
      <div className={_cn10}>
        <Stack flex={1} $sm={{
        flexDirection: 'column'
      }} $gtSm={{
        flexDirection: 'row'
      }} space="$7">
          <div className={_cn11}>
            <BusinessServices width={200} height={100} viewBox="0 0 1061 507.3972" />
            <h3 className={_cn12}>
              Business Services
            </h3>
            <p className={_cn13}>
              Manage your customers, give quotes, gain leads, grow your business. Enhanced with
              custom services to meet your needs.
            </p>
          </div>
          <div className={_cn11}>
            <ProofOfConcept width={135} height={100} viewBox="0 0 552.94084 367.92049" />
            <h3 className={_cn12}>
              Proof of Concept
            </h3>
            <p className={_cn13}>
              Have an idea but need technical expertise to get to market? Let's work together
            </p>
          </div>
          <div className={_cn11}>
            <Consultation width={120} height={100} viewBox="0 0 899.86614 731.19068" />
            <h3 className={_cn12}>
              Consultation
            </h3>
            <p className={_cn13}>
              Have an idea but not sure where to start? I can give architecture or technical
              consultations as well!
            </p>
          </div>
        </Stack>
      </div>
      <h2 ref={requestQuoteRef} className={_cn14}>
        Request a Quote
      </h2>
      <div className={_cn15}>
        <div className={_cn16}>
          <ErrorBoundary fallbackRender={props => <div>{JSON.stringify(props)}</div>}>
            <Form initialValues={initialValues} schema={schema} onSuccess={onSuccess} onError={(values: unknown) => console.error(values)}>
              <TextInput required name="name" label="Full Name" />
              <TextInput required name="email" label="Email Address" />
              <Selector required name="proposalType" label="Type of Proposal" options={options} />
              <TextInputArea required name="description" label="Description of requested services" />
              <FormSubmitButton title="Submit Request" />
              <Confirmation />
            </Form>
          </ErrorBoundary>
        </div>
      </div>
      {/* <Footer>TODO: footer</Footer> */}
    </>;
}

require("/vercel/path0/deployments/vellapps-next/pages/index.tsx.66.tamagui.css!=!/vercel/path0/deployments/vellapps-next/node_modules/tamagui-loader/dist/cjs/css.js?cssPath=/vercel/path0/deployments/vellapps-next/pages/index.tsx.66.tamagui.css!/vercel/path0/deployments/vellapps-next/pages/index.tsx")